import { version as VERSION } from "../../version";
import postRobot from "post-robot";
import Honeybadger from "@honeybadger-io/js";
import {
  appendScript,
  baseMerlinElement,
  buildFullConfig,
  displayIframeOrCreateIframe,
  fetchConfigSettings,
  getQueryParamsSettings,
  loadIframe,
  MERLIN_IFRAME,
  merlinData,
  resetParentViewportSettings,
  setupEhopeOrgButton,
  shouldOpenFirstMerlinImmediately,
  storeParentViewportSettings,
} from "./parent-utils";
import { parseShippingAddress } from "./payment-request-utils";

console.log("Starting installScript execution");

// MerlinState class to manage all state
class MerlinState {
  constructor() {
    console.log("Initializing MerlinState");
    this._stripe_v3_loaded = false;
    this._can_make_payment = false;
    this._payment_request = null;
    this._transaction_successful = false;
    this._preloaded_merlin_key = null;
    this._iframe_closed = false;
    this._merlin_iframe_ready_promise = null;
    this._merlin_iframe_ready_promise_resolve = null;
    this._payment_request_ready_promise = null;
    this._payment_request_ready_promise_resolve = null;
    
    this.ACCOUNTS_FOR_APPLE_PAY_ADDRESS = [
      "account_01GF3SDDZA9X684D11FN4FRM7D",
      "account_01GTA2MG39FHK1AW5XAERVSZXT",
    ];

    this.createPaymentRequestReadyPromise();
    this.createMerlinIframeReadyPromise();
  }

  // Promise management
  createPaymentRequestReadyPromise() {
    this._payment_request_ready_promise = new Promise((resolve) => {
      this._payment_request_ready_promise_resolve = resolve;
    });
    return this._payment_request_ready_promise;
  }

  resolvePaymentRequestReady() {
    console.log("IFRAME_PAYMENT_REQUEST_READY!");
    if (this._payment_request_ready_promise_resolve) {
      this._payment_request_ready_promise_resolve();
    }
  }

  createMerlinIframeReadyPromise() {
    this._merlin_iframe_ready_promise = new Promise((resolve) => {
      this._merlin_iframe_ready_promise_resolve = resolve;
    });
    return this._merlin_iframe_ready_promise;
  }

  resolveMerlinIframeReady() {
    console.log("MERLIN_IFRAME_READY!");
    if (this._merlin_iframe_ready_promise_resolve) {
      this._merlin_iframe_ready_promise_resolve();
    }
  }

  resetMerlinIframeReadyPromise() {
    return this.createMerlinIframeReadyPromise();
  }

  // Getters and setters
  get stripeV3Loaded() { return this._stripe_v3_loaded; }
  set stripeV3Loaded(value) { 
    console.log(`Stripe V3 loaded: ${value}`);
    this._stripe_v3_loaded = value; 
  }

  get canMakePayment() { return this._can_make_payment; }
  set canMakePayment(value) { this._can_make_payment = value; }

  get paymentRequest() { return this._payment_request; }
  set paymentRequest(value) { this._payment_request = value; }

  get transactionSuccessful() { return this._transaction_successful; }
  set transactionSuccessful(value) { this._transaction_successful = value; }

  get preloadedMerlinKey() { return this._preloaded_merlin_key; }
  set preloadedMerlinKey(value) { this._preloaded_merlin_key = value; }

  get iframeWasClosed() { return this._iframe_closed; }
  set iframeWasClosed(value) { this._iframe_closed = value; }

  getMerlinIframeReadyPromise() {
    return this._merlin_iframe_ready_promise;
  }

  getPaymentRequestReadyPromise() {
    return this._payment_request_ready_promise;
  }

  // Reset state
  reset() {
    this._stripe_v3_loaded = false;
    this._can_make_payment = false;
    this._payment_request = null;
    this._transaction_successful = false;
    this._preloaded_merlin_key = null;
    this._iframe_closed = false;
    this.resetMerlinIframeReadyPromise();
  }
}

// Create singleton instance
const merlinState = new MerlinState();

(function () {
  console.log("Starting IIFE execution");
  
  function redirectToHttps() {
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      console.log("Redirecting to HTTPS in production");
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }
  }

  if (window.merlinInstallScriptExecuted) {
    console.log("Script already executed, returning early");
    return;
  }

  window.merlinInstallScriptExecuted = true;
  console.log("installScript", VERSION);

  redirectToHttps();
  console.log("after redirectToHttps");
  setupEhopeOrgButton();
  console.log("after setupEhopeOrgButton");
  storeParentViewportSettings();
  console.log("after storeParentViewportSettings");

  // Event listeners
  console.log("Setting up postRobot event listeners");
  try {
    postRobot.on("IFRAME_PAYMENT_REQUEST_READY", () => {
      console.log("Setting up IFRAME_PAYMENT_REQUEST_READY listener");
      merlinState.resolvePaymentRequestReady();
    });
    postRobot.on("MERLIN_IFRAME_READY", (event) => {
      console.log("MERLIN_IFRAME_READY received from iframe", event);
      console.log("Setting up MERLIN_IFRAME_READY listener");
      merlinState.resolveMerlinIframeReady();
      console.log("MERLIN_IFRAME_READY resolved");
    });
    console.log("postRobot event listeners setup complete");
  } catch (error) {
    console.error("Error setting up postRobot listeners:", error);
  }

  // Window onload initialization - moved up here
  console.log("Setting up initialization handlers");
  
  async function initializeMerlin() {
    console.log("Initializing Merlin");
    console.time("FINAL_LOAD");
    
    try {
      const query_params = getQueryParamsSettings();
      console.log("Got query params:", query_params);
      
      await setupClickHandlers(query_params);
      console.log("after setupClickHandlers");
      
      appendScript("https://js.stripe.com/v2/");
      console.log("after appendScript");
      
      writeHeadStyles();
      console.log("after writeHeadStyles");

      const base_merlin_element = baseMerlinElement();
      console.log("Got base merlin element:", base_merlin_element);
      
      const open_immediately = shouldOpenFirstMerlinImmediately();
      console.log("Should open immediately:", open_immediately);

      try {
        await loadUpIframeSettings(base_merlin_element, query_params, open_immediately);
        console.timeEnd("FINAL_LOAD");
      } catch (error) {
        console.error("Failed to initialize Merlin:", error);
      }
    } catch (error) {
      console.error("Critical initialization error:", error);
    }
  }

  // Check the state of both load and DOMContentLoaded
  console.log("Document readyState:", document.readyState);
  
  if (document.readyState === 'complete') {
    // Both DOMContentLoaded and load have fired
    console.log("Document already completely loaded, initializing immediately");
    initializeMerlin();
  } else if (document.readyState === 'interactive') {
    // DOMContentLoaded has fired, but load hasn't
    console.log("DOM Content loaded but window not loaded yet, initializing immediately");
    initializeMerlin();
  } else {
    // Neither event has fired, attach to DOMContentLoaded
    console.log("Document not loaded, setting up DOMContentLoaded handler");
    document.addEventListener('DOMContentLoaded', function() {
      console.log("DOMContentLoaded fired");
      initializeMerlin();
    });
  }

  async function loadStripeV3() {
    if (merlinState.stripeV3Loaded) {
      return;
    }
    const stripe_script = document.createElement("script");
    stripe_script.src = "https://js.stripe.com/v3/";
    document.head.appendChild(stripe_script);
    merlinState.stripeV3Loaded = true;
    await new Promise((resolve) => {
      stripe_script.onload = resolve;
    });
  }

  function createLoadingPromise(name, loadingFn) {
    return {
      name,
      promise: (async () => {
        try {
          const result = await loadingFn();
          console.log(`${name} loaded successfully`);
          return result;
        } catch (error) {
          console.error(`Error loading ${name}:`, error);
          throw error;
        }
      })()
    };
  }

  function openMerlin(data) {
    const iframe = document.getElementById(MERLIN_IFRAME);
    if (iframe) {
      postRobot
        .send(iframe, "MERLIN_CONFIG", { config: data })
        .then(() => displayIframeOrCreateIframe())
        .catch((err) => console.error("Error sending config settings:", err));
    }
  }

  function writeHeadStyles() {
    const parent_css = "body.modal-open { overflow: hidden !important; position: fixed !important; width: 100vw }";
    const parent_style_element = document.createElement("style");
    parent_style_element.textContent = parent_css;
    document.head.appendChild(parent_style_element);
  }

  async function loadUpIframeSettings(merlin_elem, query_params, open_immediately) {
    try {
      const base_config = merlinData(merlin_elem, query_params);
      const base_merlin_key = base_config.widget_key;
      console.log("Base configuration initialized:", { base_config, base_merlin_key });

      if (!base_merlin_key) {
        const error = new Error("No widget key found in base config");
        Honeybadger.notify(error, {
          context: {
            message: "Missing widget key in base config",
            base_config,
            merlin_elem,
            query_params
          }
        });
        throw error;
      }

      const loadingTasks = [
        createLoadingPromise("Stripe", () => loadStripeV3()),
        createLoadingPromise("VisitorData", () => fetchConfigSettings(base_merlin_key)),
        createLoadingPromise("Iframe", async () => {
          merlinState.iframeWasClosed = false;
          loadIframe(base_config);
          return merlinState.getMerlinIframeReadyPromise();
        })
      ];

      try {
        const [stripe, visitorResponse, iframeReady] = await Promise.all(
          loadingTasks.map(task => task.promise)
        );

        const config_settings = buildFullConfig(visitorResponse, query_params, merlin_elem);
        merlinState.preloadedMerlinKey = base_merlin_key;

        if (open_immediately) {
          openMerlin(config_settings);
        } else {
          const iframe = document.getElementById(MERLIN_IFRAME);
          if (!iframe) {
            throw new Error("Iframe not found after initialization");
          }
          await postRobot.send(iframe, "MERLIN_CONFIG", { config: config_settings });
        }

        if (config_settings.payment_mode !== "stock" && config_settings.payment_mode !== "crypto") {
          const paymentSetupTasks = [
            createLoadingPromise("PaymentRequest", () => merlinState.getPaymentRequestReadyPromise()),
            createLoadingPromise("StripePayment", () => initiateStripePaymentRequest(config_settings))
          ];

          await Promise.all(paymentSetupTasks.map(task => task.promise));
        }

        return config_settings;
      } catch (taskError) {
        Honeybadger.notify(taskError, {
          context: {
            message: "Error in loading tasks",
            base_merlin_key,
            base_config
          }
        });
        throw taskError;
      }
    } catch (error) {
      console.error("Error in loadUpIframeSettings:", error);
      Honeybadger.notify(error, {
        context: {
          message: "Failed to load iframe settings",
          merlin_elem,
          query_params,
          open_immediately
        }
      });
      throw error;
    }
  }

  async function setupClickHandlers(query_params) {
    try {
      const merlin_links = document.getElementsByClassName("open-merlin");
      console.log("Found merlin links:", merlin_links.length);

      Array.from(merlin_links).forEach(link => {
        try {
          console.log("Adding click handler to link:", link);
          link.addEventListener("click", async function (e) {
            console.log("Click handler triggered");
            e.preventDefault();
            e.stopPropagation();

            try {
              console.log("iframeWasClosed:", merlinState.iframeWasClosed);
              if (merlinState.iframeWasClosed) {
                console.log("Loading iframe settings (closed case)");
                await loadUpIframeSettings(e.target, query_params, true);
              } else {
                console.log("Getting merlin data");
                const data = merlinData(e.target, query_params);
                console.log("Waiting for iframe ready");
                await merlinState.getMerlinIframeReadyPromise();
                console.log("Iframe ready");

                if (merlinState.preloadedMerlinKey === data.widget_key) {
                  console.log("Opening Merlin with existing key");
                  openMerlin(data);
                } else {
                  console.log("Fetching new config settings");
                  const visitorResponse = await fetchConfigSettings(data.widget_key);
                  const config_settings = buildFullConfig(visitorResponse, query_params, e.target);
                  const iframe_tag = document.getElementById(MERLIN_IFRAME);
                  console.log("Sending config to iframe");
                  await postRobot.send(iframe_tag, "MERLIN_CONFIG", { config: config_settings });
                  console.log("Opening Merlin with new config");
                  openMerlin(data);
                }
              }
            } catch (clickHandlerError) {
              console.error("Error in click handler:", clickHandlerError);
            }
          });
          console.log("Click handler added successfully");
        } catch (listenerError) {
          console.error("Error adding click listener:", listenerError);
        }
      });
    } catch (error) {
      console.error("Error in setupClickHandlers:", error);
      throw error; // Re-throw to be caught by the main try-catch
    }
  }

  async function initiateStripePaymentRequest(config_settings) {
    const request_address_on_apple_pay = merlinState.ACCOUNTS_FOR_APPLE_PAY_ADDRESS.includes(config_settings.account_id);
    const request_address = request_address_on_apple_pay;
    const stripe = Stripe(process.env.STRIPE_PUBLIC_KEY);
    const request_shipping = request_address;
    const required_billing_contact_fields = request_address ? ["postalAddress"] : [];
    const shipping_options = request_address ? [{
      id: "free-shipping",
      label: "Mailing Address",
      detail: "Address for paper statements",
      amount: 0,
    }] : [];

    merlinState.paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Church",
        amount: 1000,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: request_shipping,
      shippingOptions: shipping_options,
      requiredBillingContactFields: required_billing_contact_fields,
    });

    merlinState.paymentRequest.on("token", function (ev) {
      const name_array = ev.payerName.split(/\s+/);
      const first_name = name_array.slice(0, -1).join(" ");
      const last_name = name_array.pop();
      const shipping_address = parseShippingAddress(ev.shippingAddress);
      const stripe_payment_token_type = `payment_request_${ev.token.type}`;
      const email = ev.payerEmail;
      const token = ev.token.id;

      const success_response = {
        first_name,
        last_name,
        stripe_payment_token_type,
        email,
        token,
        address: shipping_address,
      };

      const childWindow = document.getElementById(MERLIN_IFRAME).contentWindow;
      postRobot.send(childWindow, "paymentRequestResponse", success_response);
      ev.complete("success");
    });

    try {
      const result = await merlinState.paymentRequest.canMakePayment();
      console.log("canMakePayment", result);
      merlinState.canMakePayment = !!result;
      console.log(`can_make_payment ${merlinState.canMakePayment}`);

      const childWindow = document.getElementById(MERLIN_IFRAME).contentWindow;
      postRobot.send(childWindow, "paymentRequestAvailable", result);
    } catch (error) {
      console.error("Error in canMakePayment:", error);
    }

    const childWindow = document.getElementById(MERLIN_IFRAME).contentWindow;
    return postRobot.send(childWindow, "paymentRequestAvailable", merlinState.canMakePayment);
  }

  function closeIframe() {
    const iframe = document.getElementById(MERLIN_IFRAME);
    iframe.parentNode.removeChild(iframe);
    resetParentViewportSettings();
    merlinState.iframeWasClosed = true;
    document.body.classList.remove("modal-open");
    merlinState.resetMerlinIframeReadyPromise();
    const close_button = document.getElementById("merlin-close-button");
    close_button.parentNode.removeChild(close_button);
  }

  // Event handlers
  postRobot.on("closeModal", () => {
    closeIframe();
    return { success: true };
  });

  postRobot.on("reloadParent", () => {
    window.location.reload();
    return { success: true };
  });

  postRobot.on("3ds-authentication-complete", () => {
    console.log("parent page 3ds-authentication-complete");
  });

  postRobot.on("getUTMParams", () => {
    const utm_params = window.location.search.substring(1);
    return utm_params.split("&").filter((param) => {
      return param.startsWith("utm_") || param.startsWith("sc");
    });
  });

  postRobot.on("transactionSuccessful", (event) => {
    const txnSuccessful = new CustomEvent("transactionSuccessful", {
      detail: {
        amount_cents: event.data.amount_cents,
        transaction_id: event.data.transaction_id,
      },
    });

    merlinState.paymentRequest = null;
    merlinState.transactionSuccessful = null;
    document.dispatchEvent(txnSuccessful);
    return true;
  });

  postRobot.on("clickedPaymentRequest", () => {
    console.log("paymentRequest.show");
    merlinState.paymentRequest.show();
  });

  postRobot.on("VISITOR_DATA_COMPLETE", () => {
    console.console.timeEnd("FINAL_LOAD");
  });

  postRobot.on("updateAmountPaymentRequest", (event) => {
    console.log("updateAmountPaymentRequest", event.data);
    if (merlinState.paymentRequest) {
      merlinState.paymentRequest.update({
        total: {
          label: event.data.account_name,
          amount: event.data.total_amount_cents,
        },
      });
    }
  });
})();