class AuthenticationService {
  constructor() {
    this.isAuthenticated = false;
    this.user = null;
  }

  /**
   * Initializes the authentication state by calling the server’s `decryptToken` endpoint.
   * If successful, sets `this.user` and `this.isAuthenticated`, and also stores some data
   * in `sessionStorage` for downstream use.
   */
  async initialize() {
    try {
      const response = await fetch(`${process.env.NETLIFY_API_DOMAIN}decryptToken`, {
        method: 'POST',
        credentials: 'include', // Ensures cookies are sent
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Server error response:', errorResponse);
        throw new Error('Network response was not ok');
      }

      const { data } = await response.json();
      this.user = data;
      this.isAuthenticated = true;
      const reformattedUser = this.reformatKeys(this.user);

      // Store user data in sessionStorage so other parts of the app can access it if needed
      if (this.user) {
        sessionStorage.setItem('RockUserID', reformattedUser.Id || '');
        sessionStorage.setItem('RockUserEmail', reformattedUser.Email || '');
        sessionStorage.setItem('RockUserLocation', reformattedUser.PrimaryCampusName || '');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      this.isAuthenticated = false;
      this.user = null;
    }
  }

  /**
   * Initiates the login process by redirecting to the Auth0 login endpoint.
   * The server-side (at `NETLIFY_API_DOMAIN`login) should handle the Auth0 flow
   * and set the appropriate cookies or tokens.
   */
  async login() {
    window.location.replace(`${process.env.NETLIFY_API_DOMAIN}login`);
  }

  /**
   * Initiates the logout process by redirecting to the Auth0 logout endpoint.
   * Also clears sessionStorage of user data.
   */
  async logout() {
    sessionStorage.removeItem('RockUserID');
    sessionStorage.removeItem('RockUserEmail');
    sessionStorage.removeItem('RockUserLocation');
    window.location.replace(`${process.env.NETLIFY_API_DOMAIN}logout`);
  }

  /**
   * Returns the current user object if authenticated, otherwise `null`.
   */
  getUser() {
    return this.reformatKeys(this.user);
  }

  /**
   * Returns a boolean indicating if the user is currently authenticated.
   */
  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  /**
   * Reformat the keys of an object to remove any namespace paths.
   */
  reformatKeys(data) {
    const reformattedData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const newKey = key.includes('/') ? key.split('/').pop() : key;

        if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
          reformattedData[newKey] = this.reformatKeys(data[key]);
        } else {
          reformattedData[newKey] = data[key];
        }
      }
    }

    return reformattedData;
  }
}

// Create a singleton instance of AuthenticationService
const AuthProvider = new AuthenticationService();

export default AuthProvider;
export { AuthenticationService, AuthProvider };
