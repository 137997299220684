import postRobot from "post-robot";
import Honeybadger from "@honeybadger-io/js";

export const MERLIN_IFRAME = "merlin-iframe";

export function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i];
    }
  }

  return null;
}

export function getQueryParamsSettings() {
  const query = window.location.search.substring(1);
  const params = getQueryParams(query);

  const new_params = {};
  for (const key in params) {
    const new_key = key.charAt(0).toLowerCase() + key.slice(1);
    new_params[new_key] = params[key];
  }

  return new_params;
}

function isMyScriptLoaded(url) {
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
}

export function appendScript(script_url) {
  if (!isMyScriptLoaded(script_url)) {
    let script_tag = document.createElement("script");
    script_tag.src = script_url;
    script_tag.async = true;
    document.body.appendChild(script_tag);
  }
}

function getQueryParams(qs) {
  if (qs) {
    qs = qs.split("+").join(" ");

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  return {};
}

function buildQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");
}

function getScripts() {
  const scripts = document.getElementsByTagName("script");
  let scripts_var = [];
  for (var i = 0; i < scripts.length; i++) {
    if (scripts[i].src) {
      scripts_var.push(scripts[i].src);
    }
  }

  return scripts_var;
}

function getWidgetKey() {
  const query_parameters = getInstallQueryParams();

  return query_parameters.key;
}

function getInstallQueryParams() {
  const srcs = getScripts();
  const install_script = srcs.find((s) => s.match("installScript"));
  const query = install_script.split("?")[1];
  return getQueryParams(query);
}

function getValueFromWindowSettings(key) {
  return (
    window.simpleDonationMerlinSettings &&
    window.simpleDonationMerlinSettings[key]
  );
}

export async function loadIframe(base_merlin_data) {
  console.log("Starting loadIframe");
  let iframe_tag = document.getElementById(MERLIN_IFRAME);
  if (!iframe_tag) {
    console.log("Creating new iframe");
    iframe_tag = document.createElement("iframe");
    iframe_tag.id = MERLIN_IFRAME;
    iframe_tag.allowPaymentRequest = true;

    const iframe_styles = iframeStyles("none");
    console.log("Setting iframe styles:", iframe_styles);

    iframe_tag.setAttribute("style", iframe_styles);

    document.body.appendChild(iframe_tag);
    console.log("Iframe appended to body");
  } else {
    console.log("Found existing iframe");
  }

  const domain = window.location.protocol + "//" + window.location.host;
  const primary_color = base_merlin_data.primary_color;
  const show_close_x = base_merlin_data.show_close_x;
  const css_file = base_merlin_data.css_file;
  const query_string = buildQueryString({
    domain: domain,
    primary_color: primary_color,
    show_close_x: show_close_x,
    css_file: css_file,
  });

  const src = `${process.env.MERLIN_HOST}/widget?${query_string}`;
  console.log("Setting iframe src:", src);
  iframe_tag.src = src;

  // Add load event listener
  iframe_tag.addEventListener('load', () => {
    console.log("Iframe loaded");
  });
  
  iframe_tag.addEventListener('error', (error) => {
    console.error("Iframe failed to load:", error);
    Honeybadger.notify(error, {
      context: {
        message: "Iframe failed to load",
        src: src,
        base_merlin_data: base_merlin_data
      }
    });
  });
}

export function storeParentViewportSettings() {
  const viewport = getMeta("viewport");
  if (viewport) {
    window.parent_viewport_settings = viewport.getAttribute("content");
    viewport.setAttribute(
      "content",
      "width=device-width, user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no"
    );
  }
}

function defaultSettings(a, b) {
  return a || b;
}

function buildWidgetConfig(settings = {}) {
  const install_query_parameters = getInstallQueryParams();
  const widget_key = defaultSettings(settings.merlinKey, getWidgetKey());
  const css_file = defaultSettings(
    settings.cssFile,
    install_query_parameters.cssFile
  );
  const primary_color = defaultSettings(
    settings.merlinPrimaryColor,
    install_query_parameters.primaryColor
  );
  const fund_id = defaultSettings(
    settings.merlinFundId,
    getValueFromWindowSettings("fund_id")
  );
  const rock_mobile_px = defaultSettings(
    settings.merlinRockPx,
    install_query_parameters.rockMobilePx
  );
  const memo = settings.merlinMemo;
  const store_mode =
    settings.merlinStoreMode === true || settings.merlinStoreMode === "true";
  const payment_mode = settings.merlinPaymentMode || "card/ach";
  const show_close_x = settings.merlinShowCloseX;
  const marketing_code = settings.marketingCode;
  const first_name = settings.firstName;
  const last_name = settings.lastName;
  const email = settings.email;
  const recurring_option = settings.merlinFrequency;
  const recurring_collect_by_date = settings.recurringCollectByDate;
  const recurring_total_cents = parseInt(settings.recurringTotalCents, 10);
  const amount_cents_might_be_nan = defaultSettings(
    settings.merlinAmountCents,
    getValueFromWindowSettings("amount_cents")
  );
  const amount_cents = isNaN(amount_cents_might_be_nan) ? null : parseInt(amount_cents_might_be_nan, 10);

  const pledge_recurring_amount_cents = parseInt(settings.pledgeRecurringAmountCents, 10);
  const pledge_recurring_interval = settings.pledgeRecurringInterval;

  const url = window.location.host + window.location.pathname;
  const merlin_person_id = getValueFromWindowSettings("personId");

  const giving_flow = getValueFromWindowSettings("giving_flow");

  const phone_number = getValueFromWindowSettings("phone_number");
  const text_to_give_number = getValueFromWindowSettings("text_to_give_number");

  //
  // Entity and EntityType used for Fundraising Opportunity in Rock
  //
  const rock_entity_type_id = settings.merlinFormEntityTypeId;
  const rock_entity_id = settings.merlinFormEntityId;
  const rock_financial_account_id = settings.rockFinancialAccountId;

  const query_params = {
    amount_cents: amount_cents,
    css_file: css_file,
    email: email,
    first_name: first_name,
    selected_fund_id: fund_id,
    giving_flow: giving_flow,
    last_name: last_name,
    marketing_code: marketing_code,
    memo: memo,
    payment_mode: payment_mode,
    person_id: merlin_person_id,
    phone_number: phone_number,
    pledge_recurring_amount_cents: pledge_recurring_amount_cents,
    pledge_recurring_interval: pledge_recurring_interval,
    primary_color: primary_color,
    recurring_option: recurring_option,
    recurring_collect_by_date: recurring_collect_by_date,
    recurring_total_cents: recurring_total_cents,
    rock_mobile_px: rock_mobile_px,
    store_mode: store_mode,
    text_to_give_number: text_to_give_number,
    url: url,
    widget_key: widget_key,
    rock_entity_type_id: rock_entity_type_id,
    rock_entity_id: rock_entity_id,
    rock_financial_account_id: rock_financial_account_id,
    show_close_x: show_close_x,
  };

  return Object.keys(query_params).reduce((acc, key) => {
    if (query_params[key] !== null && query_params[key] !== undefined) {
      acc[key] = query_params[key];
    }
    return acc;
  }, {});
}

export function setupEhopeOrgButton() {
  if (window.location.host == "www.ehope.org") {
    const giveXPath = "//span[contains(text(),'Give Now')]";
    const giveNowButton = document.evaluate(
      giveXPath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;

    if (giveNowButton) {
      giveNowButton.classList.add("open-merlin");
      giveNowButton.dataset.merlinKey = "01E9E8F17NCXPHS1H73D15EC59";
    }
  }
}

function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

function findMerlinDataset(el) {
  if (el.classList.contains("open-merlin")) {
    return el.dataset;
  }

  if (el.dataset.merlinKey) {
    return el.dataset;
}

  const ancestor = findAncestor(el, "open-merlin");
  return ancestor ? ancestor.dataset : {};
}

export function merlinData(el, query_params) {
  const dataset = findMerlinDataset(el);
  const settings = Object.assign({}, query_params, dataset);
  return buildWidgetConfig(settings);
}

export function baseMerlinElement() {
  const auto_open_merlin_links = document.querySelectorAll(
    "[data-merlin-autoload='true']"
  );

  const auto_open_merlin_link = auto_open_merlin_links[0];

  if (auto_open_merlin_link) {
    return auto_open_merlin_link;
  } else {
    const script_key = getWidgetKey();
    const merlin_links = Array.from(document.getElementsByClassName("open-merlin"));
    if(script_key) {
      return merlin_links[0];
    }
    const merlin_links_with_data_merlin_key = merlin_links.filter(link => link.dataset.merlinKey);
    if (merlin_links_with_data_merlin_key.length > 0) {
      return merlin_links_with_data_merlin_key[0];
    }
    return merlin_links[0];
  }
}

function recurringOption(params) {
  const stated_recurring_option = params.default_recurring_option
  if (params.recurring_button_name == 'WEEKLY' && stated_recurring_option == 'MONTHLY') {
    return 'WEEKLY'
  }
  return stated_recurring_option
}

export function buildFullConfig(fetchedSettings, query_params, element) {
  const recurring_option = recurringOption(fetchedSettings.account)
  let api_loaded_settings = Object.assign({}, fetchedSettings.account, {
    paypal_token: fetchedSettings.paypal_token,
    recurring_option: recurring_option,
    account_data_loaded: true,
  });

  // Create a new object without default_recurring_option
  if ('default_recurring_option' in api_loaded_settings) {
    const { default_recurring_option, ...rest } = api_loaded_settings;
    api_loaded_settings = rest;
  }

  const transformations = {
    id: "account_id",
    name: "account_name",
    logo_url: "account_logo_url",
    starting_amount_cents: "amount_cents",
    default_checked_cover_the_fee: "fee_covered",
    url: "account_url",
  };

  // Transform the object
  const key_transformed_settings = Object.keys(api_loaded_settings).reduce(
    (acc, key) => {
      const newKey = transformations[key] || key; // Transform the key if it matches, otherwise keep the original key
      acc[newKey] = api_loaded_settings[key]; // Assign the value to the new or original key
      return acc;
    },
    {}
  );

  const dataset = merlinData(element, query_params);

  return Object.assign({}, key_transformed_settings, dataset);
}

export function iframeStyles(display) {
  return `display: ${display}; margin: 0 !important; padding: 0 !important; border: 0 !important; width: 100% !important; height: 100% !important; position: fixed !important; opacity: 1 !important; top: 0 !important; left: 0 !important; right: 0 !important; bottom: 0 !important; transform: translateZ(100px) !important; z-index: 2147483646 !important; max-width: 100% !important;`;
}

export async function fetchConfigSettings(elem_key) {
  try {
    const install_script_widget_key = getWidgetKey();
    const merlin_key = elem_key || install_script_widget_key;

    if (!merlin_key) {
      const error = new Error("No widget key found");
      Honeybadger.notify(error, {
        context: {
          message: "Failed to get widget key",
          elem_key: elem_key,
          install_script_widget_key: install_script_widget_key
        }
      });
      throw error;
    }

    const response = await fetch(
      `${process.env.API_HOST}/merlin/visitor?key=${merlin_key}`,
      {
        method: "GET",
        credentials: "omit",
      }
    );

    if (!response.ok) {
      const error = new Error(`Failed to fetch config settings: ${response.status} ${response.statusText}`);
      Honeybadger.notify(error, {
        context: {
          message: "Failed to fetch config settings",
          status: response.status,
          statusText: response.statusText,
          merlin_key: merlin_key
        }
      });
      throw error;
    }

    const fetchedSettings = await response.json();
    return fetchedSettings;
  } catch (error) {
    console.error("Error fetching config settings:", error);
    Honeybadger.notify(error, {
      context: {
        message: "Error in fetchConfigSettings",
        elem_key: elem_key
      }
    });
    throw error; // Re-throw to let caller handle the error
  }
}

export async function displayIframeOrCreateIframe() {
  let iframe_tag = document.getElementById(MERLIN_IFRAME);

  if (!iframe_tag) {
    iframe_tag = document.createElement("iframe");
    iframe_tag.id = MERLIN_IFRAME;
    iframe_tag.allowPaymentRequest = true;

    const iframe_styles = iframeStyles("block");

    iframe_tag.setAttribute("style", iframe_styles);

    document.body.appendChild(iframe_tag);
  } else {
    iframe_tag.style.display = "block";
    iframe_tag.style.opacity = 1;
  }

  document.body.classList.add("modal-open");
}

export function shouldOpenFirstMerlinImmediately() {
  const auto_open_merlin_links = document.querySelectorAll(
    "[data-merlin-autoload='true']"
  );

  const auto_open_merlin_link = auto_open_merlin_links[0];

  if (auto_open_merlin_link) {
    return true;
  } else {
    return (
      window.simpleDonationMerlinSettings &&
      window.simpleDonationMerlinSettings["open"]
    );
  }
}

export function resetParentViewportSettings() {
  const viewport = getMeta("viewport");
  if (viewport) {
    viewport.setAttribute("content", window.parent_viewport_settings);
  }
}
